<!--商品详情-->
<template>
  <div class="w store-content">
    <div class="gray-box">
      <div class="gallery-wrapper">
        <div class="gallery">
          <div class="thumbnail">
            <!-- <ul>
              <li v-for="(item,i) in product.productImageSmall" :key="i" :class="{on:big===item}" @click="big=item">
                <img v-lazy="item" :alt="product.name">
              </li>
            </ul> -->
          </div>
          <div class="thumb">
            <div class="big">
              <img :src="baseURL + product.mainImage" :alt="product.name">
            </div>
          </div>
        </div>
      </div>
      <!--右边-->
      <div class="banner">
        <div class="sku-custom-title">
          <h4>{{product.name}}</h4>
          <h6>
            <span>{{product.detail}}</span>
            <span class="price">
              <em>¥</em><i>{{product.price.toFixed(2)}}</i></span>
          </h6>
        </div>
        <div class="num">
          <span class="params-name">数量</span>
          <buy-num @edit-num="editNum" :limit="Number(product.stock)" :num="productNum"></buy-num>
        </div>
        <div class="buy">
          <y-button text="加入购物车"
                    @btnClick="addCart(product.proid)"
                    classStyle="main-btn"
                    style="width: 145px;height: 50px;line-height: 48px"></y-button>
          <y-button text="现在购买"
                    @btnClick="checkout()"
                    style="width: 145px;height: 50px;line-height: 48px;margin-left: 10px"></y-button>
        </div>
      </div>
    </div>
    <!--产品信息-->
    <div class="item-info">
      <y-shelf title="产品信息">
        <div slot="content">
          <div class="img-item" v-if="productMsg">
              <img :src="'https://www.xiaoguobx.com/'+productMsg" :alt="product.detail">
          </div>
          <div class="img-item" v-if="images">
            <div v-for="(item , index) in images" :key="index">
              <img :src="'https://www.xiaoguobx.com'+ item" alt="纵享丝滑">
            </div>
          </div>
          <div class="no-info" v-else>
            <img src="/static/images/no-data.png">
            <br>
            该商品暂无内容数据
          </div>
        </div>
      </y-shelf>
    </div>
  </div>
</template>
<script>
  // import { productDet, addCart } from '/api/goods'
  import { XGShoppingSelectShopping, XGShoppingAddCart, XGShoppingSelectCart } from '../../server/api'
  import store from "@/vuex/store";
  import YShelf from '@/components/shelf'
  import BuyNum from '@/components/buynum.vue'
  import YButton from '@/components/YButton.vue'
  // import { getStore } from '/utils/storage'
  export default {
    data () {
      return {
        productMsg: "",
        images:[],
        small: [],
        big: '',
        product: {
          price: 0
        },
        productNum: 1,
        userId: ''
      }
    },
    computed: {
      IsLogin(){
        return this.$store.state.login
      },
      showMoveImg(){
        return this.$store.state.showMoveImg
      },
      showCart(){
        return this.$store.state.showCart
      },
    },
    methods: {
      // ...mapMutations(['ADD_CART', 'ADD_ANIMATION', 'SHOW_CART']),
      _productDet (proid) {
        const that = this
        let params = {
            proid: proid
        }
        XGShoppingSelectShopping(params).then((res) => {
          if(res.code === 200){
           const {detail,mainImage,name,price,proid,stock,subImages, images} = res.data.rows[0]
            that.product = {
              "proid": proid,
              "price": price,
              "name": name,
              "detail": detail,
              "stock": stock,
              "mainImage": mainImage,
              "productImageSmall": subImages,
              "images": images,
          }
          }else{
            this.$message({
            message: "查询失败，请稍后再试",
            type: 'error'
          })
          }
        //   let result = res.result
          this.productMsg =  this.product.productImageSmall || ''
          this.images = that.product.images.split(',')
          this.small = this.product.subImages
          this.big = this.mainImage
        })
      },
      addCart (id) {
        if(!this.$store.state.login){
          this.$router.push({
            path: '/shopping/login'
          })
          return
        }
        const that = this
        if(!this.productNum) return
        // if (!this.showMoveImg) {     // 动画是否在运动
          // if (this.IsLogin) { // 登录了 直接存在用户名下
            let param = {
              userId:null,
              proId: id,
              add: this.productNum,
              cut:null,
              createTime:null
            }
            XGShoppingAddCart(param).then(res => {
              that.$message({
              message: res.data,
              type: 'success'
            })
            XGShoppingSelectCart().then((res) =>{
              if(res.code === 200 && res.data){
                let count = 0
                res.data.forEach(item =>{
                  count+=item.proNumber
                })
                store.commit("SET_SHOPPINGCARTCOUNT",count)
              }
            })
              // 并不重新请求数据
              // this.ADD_CART({
              //   proid: id,
              //   price: price,
              //   name: name,
              //   productImg: img,
              //   productNum: this.productNum
              // })
            })
          // } else { // 未登录 vuex
          //   this.$router.push({
          //   path: '/shopping/login'
          // })
            // this.ADD_CART({
            //   proid: id,
            //   price: price,
            //   name: name,
            //   productImg: img,
            //   productNum: this.productNum
            // })
          // }
          // 加入购物车动画
          // var dom = event.target
          // 获取点击的坐标
          // let elLeft = dom.getBoundingClientRect().left + (dom.offsetWidth / 2)
          // let elTop = dom.getBoundingClientRect().top + (dom.offsetHeight / 2)
          // 需要触发
          // this.ADD_ANIMATION({moveShow: true, elLeft: elLeft, elTop: elTop, img: img})
          // if (!this.showCart) {
          //   this.SHOW_CART({showCart: true})
          // }
        // }
      },
      checkout () {
        if(!this.$store.state.login){
          this.$router.push({
            path: '/shopping/login'
          })
          return
        }
        // 处理商品格式
        this.product= {...this.product,proNumber:this.productNum}
        store.commit("SET_GOODSLIST",[this.product])
        this.$router.push({path: '/shopping/checkout'})
      },
      editNum (a,b,c,num) {
        this.productNum = num
      }
    },
    components: {
      YShelf, BuyNum, YButton
    },
    activated() {
      let id = this.$route.query.proid
      this._productDet(id)
      this.productNum = 1
      // this.userId = getStore('userId')
    }
  }
</script>
<style lang="scss" scoped>
  @import "../../assets/style/mixin";
  @import "../../assets/style/index";

  .store-content {
    clear: both;
    width: 1220px;
    min-height: 600px;
    padding: 0 0 25px;
    margin: 0 auto;
  }

  .gray-box {
    display: flex;
    padding: 60px;
    margin: 100px 0 20px;
    .gallery-wrapper {
      .gallery {
        display: flex;
        width: 540px;
        .thumbnail {
          li:first-child {
            margin-top: 0px;
          }
          li {
            @include wh(80px);
            margin-top: 10px;
            padding: 12px;
            border: 1px solid #f0f0f0;
            border: 1px solid rgba(0, 0, 0, .06);
            border-radius: 5px;
            cursor: pointer;
            &.on {
              padding: 10px;
              border: 3px solid #ccc;
              border: 3px solid rgba(0, 0, 0, .2);
            }
            img {
              display: block;
              @include wh(100%);
            }
          }
        }
        .thumb {
          .big {
            margin-left: 20px;
          }
          img {
            display: block;
            @include wh(440px)
          }
        }
      }
    }
    // 右边
    .banner {
      width: 450px;
      margin-left: 10px;
      h4 {
        font-size: 24px;
        line-height: 1.25;
        color: #000;
        margin-bottom: 13px;
      }
      h6 {
        font-size: 14px;
        line-height: 1.5;
        color: #bdbdbd;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .sku-custom-title {
        overflow: hidden;
        padding: 8px 8px 18px 10px;
        position: relative;
      }
      .params-name {
        padding-right: 20px;
        font-size: 14px;
        color: #8d8d8d;
        line-height: 36px;
      }
      .num {
        padding: 29px 0 8px 10px;
        border-top: 1px solid #ebebeb;
        display: flex;
        align-items: center;
      }
      .buy {
        position: relative;
        border-top: 1px solid #ebebeb;
        padding: 30px 0 0 10px;
      }
    }
  }

  .item-info {

    .gray-box {
      padding: 0;
      display: block;
    }
    .img-item {
      width: 1220px;
      // padding: 1vw;
      text-align: center;
      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }
  }

  .no-info {
    padding: 200px 0;
    text-align: center;
    font-size: 30px;
  }

  .price {
    display: block;
    color: #d44d44;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    i {
      padding-left: 2px;
      font-size: 24px;
    }
  }
</style>
